import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { size } from 'lodash'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  ErrorMessage,
  Layout,
  ListingTable,
  ListingPageSkeleton,
  SEO,
} from 'components'
import {
  DEFAULT_LISTING_LIMITS,
  ERROR_FETCHING_LISTINGS,
  LISTING_STATUS,
} from 'constants/listingConstants'
import { NOT_FOUND_ENDPOINT } from 'constants/apiUrls'
import { getListing } from 'services/ListingService'
import { getOffersByMakerId } from 'services/OfferService'

const useStyles = makeStyles(theme => ({
  border: {
    boxShadow: `0px 24px 27px ${theme.palette.border.shadow}`,
  },
  mainContainer: {
    paddingTop: '50px',
    paddingLeft: '100px',
    paddingRight: '100px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '20px',
      paddingLeft: '32px',
      paddingRight: '32px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '20px',
      paddingLeft: '44px',
      paddingRight: '44px',
    },
  },
}))

const { SUBMITTED } = LISTING_STATUS

const ListingsPage = ({ location }) => {
  const loggedUser = useSelector(state => state.userState.user)
  const { state = {} } = location
  const collapsedListing = state ? state.collapsedListing : ''
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState('')
  const [hasNext, setHasNext] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [latestElements, setLatestElements] = useState([])
  const [listings, setListings] = useState([])
  const [listingsPage, setListingsPage] = useState(0)
  const [listingLimit, setListingsLimit] = useState(DEFAULT_LISTING_LIMITS[0])
  const [loading, setLoading] = useState(false)
  const [offers, setOffers] = useState([])

  useEffect(() => {
    if (!loggedUser.isMaker && !loggedUser.isOperator) {
      navigate(NOT_FOUND_ENDPOINT)
    }
  }, [loggedUser])

  useEffect(() => {
    const getInitialListings = async () => {
      setLoading(true)
      setErrorMessage('')
      try {
        const { lastElement, listings } = await getListing(
          null,
          DEFAULT_LISTING_LIMITS[2],
          null,
          [SUBMITTED]
        )
        const newOffers = await getOffersByMakerId(loggedUser.uid)
        setHasNext(size(listings) === DEFAULT_LISTING_LIMITS[2])
        setListings(listings)
        setLatestElements([lastElement])
        setOffers(newOffers)
      } catch (error) {
        setErrorMessage(ERROR_FETCHING_LISTINGS)
      }
      setLoading(false)
    }

    if (
      loggedUser &&
      !loggedUser.isAnonymous &&
      !size(listings) &&
      !size(latestElements)
    ) {
      getInitialListings()
    }
  }, [listings, latestElements, loggedUser])

  const fetchListings = async (
    limit = DEFAULT_LISTING_LIMITS[2],
    lastElement,
    newPage
  ) => {
    let hasUpdated = false
    setIsFetching(true)
    setErrorMessage('')
    setListingsLimit(limit)
    try {
      const { lastElement: newLastElement, listings } = await getListing(
        null,
        limit,
        lastElement,
        [SUBMITTED]
      )
      if (size(listings) > 0) {
        hasUpdated = true
        setHasNext(size(listings) === limit)
        setListings(listings)
        setListingsPage(newPage)
        if (lastElement) {
          setLatestElements([...latestElements, newLastElement])
        } else {
          setLatestElements([newLastElement])
        }
      } else {
        setHasNext(false)
      }
    } catch (error) {
      setErrorMessage(ERROR_FETCHING_LISTINGS)
    }
    setIsFetching(false)
    return hasUpdated
  }

  const nextPage = async (page, limit) => {
    const lastElement = latestElements[page - 1]
    return fetchListings(limit, lastElement, page)
  }

  const prevPage = async (page, limit) => {
    const lastElement = page > 0 ? latestElements[page - 1] : null
    latestElements.pop()
    latestElements.pop()
    setLatestElements(latestElements)
    fetchListings(limit, lastElement, page)
  }

  const loadNewOffers = async () => {
    const newOffers = await getOffersByMakerId(loggedUser.uid)
    setOffers(newOffers)
  }

  const refreshCurrentListingsPage = async () => {
    latestElements.pop()
    setLatestElements(latestElements)
    const lastElement =
      listingsPage > 0 ? latestElements[listingsPage - 1] : null
    fetchListings(listingLimit, lastElement, listingsPage)
  }

  return (
    <Layout>
      <SEO title="Listings" />
      {loading ? (
        <ListingPageSkeleton />
      ) : (
        <Grid className={classes.mainContainer} container>
          <Grid item xs={12}>
            <Typography className={classes.upperCase} variant="h4">
              Hi&nbsp;
              {loggedUser && loggedUser.first_name
                ? loggedUser.first_name
                : 'There'}
              ,
            </Typography>
          </Grid>
          <Grid className={classes.border} item xs={12}>
            <ErrorMessage message={errorMessage} />
            <ListingTable
              collapsedListing={collapsedListing}
              displayMakeOfferButton={loggedUser && !loggedUser.isAnonymous}
              displayModelImage={true}
              currentUser={loggedUser}
              fetchListings={fetchListings}
              hasNext={hasNext}
              initRowsPerPage={DEFAULT_LISTING_LIMITS[2]}
              isFetching={isFetching}
              listings={listings}
              offers={offers}
              loadNewOffers={loadNewOffers}
              nextPage={nextPage}
              prevPage={prevPage}
              refreshCurrentListingsPage={refreshCurrentListingsPage}
            />
          </Grid>
        </Grid>
      )}
    </Layout>
  )
}

ListingsPage.propTypes = {
  location: PropTypes.object,
}

ListingsPage.defaultProps = {
  location: {
    state: {
      collapsedListing: '',
    },
  },
}

export default ListingsPage
